var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MostUsedStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('titles.mostUsed')))]), _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.assetDimensionsMapped, function (dimension) {
    return _c('AssetDimension', {
      key: dimension.id,
      attrs: {
        "dimension": dimension,
        "showCount": true
      },
      on: {
        "addDimension": function addDimension($event) {
          return _vm.$emit('addDimension', $event);
        },
        "removeDimension": function removeDimension($event) {
          return _vm.$emit('removeDimension', $event);
        }
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }