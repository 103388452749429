<template>
  <MostUsedStyled>
    <div class="title">{{ $t('titles.mostUsed') }}</div>
    <div class="wrapper">
      <AssetDimension
        v-for="dimension in assetDimensionsMapped"
        :key="dimension.id"
        :dimension="dimension"
        :showCount="true"
        @addDimension="$emit('addDimension', $event)"
        @removeDimension="$emit('removeDimension', $event)"
      />
    </div>
  </MostUsedStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import AssetDimension from '../AssetDimension'

import MOST_USED_ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/mostUsedAssetDimensions.gql'

const MostUsedStyled = styled('div')`
  grid-area: most-used;
  padding: 0.5rem;
  .title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  .wrapper {
    overflow: auto;
  }
`

export default {
  props: {
    dimensionsSelected: {
      type: Array,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
    canAddDimension: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    MostUsedStyled,
    AssetDimension,
  },
  data() {
    return {
      mostUsedAssetDimensions: [],
    }
  },
  computed: {
    mostUsedAssetDimensionsSliced() {
      return this.mostUsedAssetDimensions.slice(0, 10)
    },
    assetDimensionsMapped() {
      const mapped = this.mostUsedAssetDimensionsSliced
        .map(ad => {
          const selectedWidgetIsCompatible = this.selectedWidget ? ad.compatibleWidgets.find(f => f.id === this.selectedWidget.id) : true
          const isEnabled = this.canAddDimension && selectedWidgetIsCompatible
          return {
            ...ad,
            isSelected: this.dimensionsSelected.find(ds => ds.id === ad.id),
            isEnabled,
          }
        })
        .filter(dimension => dimension.compatibleWidgets.length > 0)
      return mapped
    },
  },
  apollo: {
    mostUsedAssetDimensions: {
      query: MOST_USED_ASSET_DIMENSIONS_QUERY,
    },
  },
}
</script>
